/* tslint:disable */
/* eslint-disable */
/**
 * Agora Livestock API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0 (v2)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { APIException } from '../model';
// @ts-ignore
import { MediaSignature } from '../model';
// @ts-ignore
import { MediaSignatureCreate } from '../model';
// @ts-ignore
import { OrgMedia } from '../model';
// @ts-ignore
import { OrgMediaAutocomplete } from '../model';
// @ts-ignore
import { OrgMediaCreate } from '../model';
// @ts-ignore
import { PatchedOrgMediaUpdate } from '../model';
// @ts-ignore
import { ValidationError } from '../model';
/**
 * OrganisationMediaApi - axios parameter creator
 * @export
 */
export const OrganisationMediaApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Once archived, a Media item cannot be added to  new fields. If the link already existed on an update then it can continue to be linked
         * @summary Archive the Org Media
         * @param {string} buyerOrgId 
         * @param {string} mediaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgMediaArchiveCreate: async (buyerOrgId: string, mediaId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgMediaArchiveCreate', 'buyerOrgId', buyerOrgId)
            // verify required parameter 'mediaId' is not null or undefined
            assertParamExists('orgMediaArchiveCreate', 'mediaId', mediaId)
            const localVarPath = `/v2/org/{buyer_org_id}/media/{media_id}/archive`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)))
                .replace(`{${"media_id"}}`, encodeURIComponent(String(mediaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unarchive the Org Media
         * @param {string} buyerOrgId 
         * @param {string} mediaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgMediaArchiveDestroy: async (buyerOrgId: string, mediaId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgMediaArchiveDestroy', 'buyerOrgId', buyerOrgId)
            // verify required parameter 'mediaId' is not null or undefined
            assertParamExists('orgMediaArchiveDestroy', 'mediaId', mediaId)
            const localVarPath = `/v2/org/{buyer_org_id}/media/{media_id}/archive`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)))
                .replace(`{${"media_id"}}`, encodeURIComponent(String(mediaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Searches against the Orgs Media using the following fields: - name (filename) - description  When searching, those which have an exact match of the whole string are first, followed by those which start with the query, and finally those which contain the search param.  Returns the first 10 results 
         * @summary Autocomplete Search
         * @param {string} buyerOrgId 
         * @param {string} [includeArchived] Includes archived Media. values: \&#39;1\&#39; or \&#39;0\&#39;, default: \&#39;0\&#39;
         * @param {string} [q] Query parameter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgMediaAutocompleteList: async (buyerOrgId: string, includeArchived?: string, q?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgMediaAutocompleteList', 'buyerOrgId', buyerOrgId)
            const localVarPath = `/v2/org/{buyer_org_id}/media/autocomplete`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (includeArchived !== undefined) {
                localVarQueryParameter['include_archived'] = includeArchived;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Media item
         * @param {string} buyerOrgId 
         * @param {OrgMediaCreate} orgMediaCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgMediaCreate: async (buyerOrgId: string, orgMediaCreate: OrgMediaCreate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgMediaCreate', 'buyerOrgId', buyerOrgId)
            // verify required parameter 'orgMediaCreate' is not null or undefined
            assertParamExists('orgMediaCreate', 'orgMediaCreate', orgMediaCreate)
            const localVarPath = `/v2/org/{buyer_org_id}/media`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orgMediaCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *   # Image transformation  This works on any endpoints below which return the `OrgMediaSerializer`, except /autocomplete.  To generate your own secure URL\'s with custom transformations you need to pass in your transforms via the querystring. Any number can be passed through and the name **MUST** begin with `t__`.  eg `?t__{name}={transformations}>&t__{another name}={transformations 2}`  Where - `{name}` is the key you want it returned as in the `urls` - `{transformations}` is the transformations you want done to the image.  For example, this sample image on Cloudinary we have: https://tx.cloudinary.com/?publicId=docs/blue_sweater&transformationString=a_vflip/e_pixelate_faces:5/c_thumb,w_100   1. cropped it as a thumb with a width of 100 2. flipped it vertically 3. pixelated the faces using pixels 5x5 in size  It results in the transformation url: https://res.cloudinary.com/demo/image/upload/<span style=\"color:red; font-weight: bold;\">a_vflip/e_pixelate_faces:5/c_thumb,w_100</span>/docs/blue_sweater.jpg`  ![Transformed image](https://res.cloudinary.com/demo/image/upload/a_vflip/e_pixelate_faces:5/c_thumb,w_100/docs/blue_sweater.jpg)  The `{transformations}` part we want to use is that in red: `a_vflip/e_pixelate_faces:5/c_thumb,w_100`  and we want to call the transform `flip_the_face` so we\'d pass it value the querystring as:  `?t__flip_the_face=a_vflip/e_pixelate_faces:5/c_thumb,w_100`  This would result in the `urls` key returning the key and URL you can use to display the transformed image.  ``` {     \"urls\": {         \"download\": \"https://...\",         \"original\": \"https://...\",         \"flip_the_face\": \"https://res.cloudinary.com/demo/image/upload/a_vflip/e_pixelate_faces:5/c_thumb,w_100/docs/blue_sweater.jpg\"     } } ``` 
         * @summary Org\'s media
         * @param {string} buyerOrgId 
         * @param {string} [includeArchived] 
         * @param {Array<'-created' | '-name' | 'created' | 'name'>} [ordering] Ordering
         * @param {string} [q] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgMediaList: async (buyerOrgId: string, includeArchived?: string, ordering?: Array<'-created' | '-name' | 'created' | 'name'>, q?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgMediaList', 'buyerOrgId', buyerOrgId)
            const localVarPath = `/v2/org/{buyer_org_id}/media`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (includeArchived !== undefined) {
                localVarQueryParameter['include_archived'] = includeArchived;
            }

            if (ordering) {
                localVarQueryParameter['ordering'] = ordering.join(COLLECTION_FORMATS.csv);
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Media item
         * @param {string} buyerOrgId 
         * @param {string} mediaId 
         * @param {PatchedOrgMediaUpdate} [patchedOrgMediaUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgMediaPartialUpdate: async (buyerOrgId: string, mediaId: string, patchedOrgMediaUpdate?: PatchedOrgMediaUpdate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgMediaPartialUpdate', 'buyerOrgId', buyerOrgId)
            // verify required parameter 'mediaId' is not null or undefined
            assertParamExists('orgMediaPartialUpdate', 'mediaId', mediaId)
            const localVarPath = `/v2/org/{buyer_org_id}/media/{media_id}`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)))
                .replace(`{${"media_id"}}`, encodeURIComponent(String(mediaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedOrgMediaUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *   # Image transformation  This works on any endpoints below which return the `OrgMediaSerializer`, except /autocomplete.  To generate your own secure URL\'s with custom transformations you need to pass in your transforms via the querystring. Any number can be passed through and the name **MUST** begin with `t__`.  eg `?t__{name}={transformations}>&t__{another name}={transformations 2}`  Where - `{name}` is the key you want it returned as in the `urls` - `{transformations}` is the transformations you want done to the image.  For example, this sample image on Cloudinary we have: https://tx.cloudinary.com/?publicId=docs/blue_sweater&transformationString=a_vflip/e_pixelate_faces:5/c_thumb,w_100   1. cropped it as a thumb with a width of 100 2. flipped it vertically 3. pixelated the faces using pixels 5x5 in size  It results in the transformation url: https://res.cloudinary.com/demo/image/upload/<span style=\"color:red; font-weight: bold;\">a_vflip/e_pixelate_faces:5/c_thumb,w_100</span>/docs/blue_sweater.jpg`  ![Transformed image](https://res.cloudinary.com/demo/image/upload/a_vflip/e_pixelate_faces:5/c_thumb,w_100/docs/blue_sweater.jpg)  The `{transformations}` part we want to use is that in red: `a_vflip/e_pixelate_faces:5/c_thumb,w_100`  and we want to call the transform `flip_the_face` so we\'d pass it value the querystring as:  `?t__flip_the_face=a_vflip/e_pixelate_faces:5/c_thumb,w_100`  This would result in the `urls` key returning the key and URL you can use to display the transformed image.  ``` {     \"urls\": {         \"download\": \"https://...\",         \"original\": \"https://...\",         \"flip_the_face\": \"https://res.cloudinary.com/demo/image/upload/a_vflip/e_pixelate_faces:5/c_thumb,w_100/docs/blue_sweater.jpg\"     } } ``` 
         * @summary Fetch a specific Media item
         * @param {string} buyerOrgId 
         * @param {string} mediaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgMediaRetrieve: async (buyerOrgId: string, mediaId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgMediaRetrieve', 'buyerOrgId', buyerOrgId)
            // verify required parameter 'mediaId' is not null or undefined
            assertParamExists('orgMediaRetrieve', 'mediaId', mediaId)
            const localVarPath = `/v2/org/{buyer_org_id}/media/{media_id}`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)))
                .replace(`{${"media_id"}}`, encodeURIComponent(String(mediaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generates a signature to allow content to be uploaded to Cloudinary directly
         * @param {string} buyerOrgId 
         * @param {MediaSignatureCreate} mediaSignatureCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgMediaSignatureCreate: async (buyerOrgId: string, mediaSignatureCreate: MediaSignatureCreate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgMediaSignatureCreate', 'buyerOrgId', buyerOrgId)
            // verify required parameter 'mediaSignatureCreate' is not null or undefined
            assertParamExists('orgMediaSignatureCreate', 'mediaSignatureCreate', mediaSignatureCreate)
            const localVarPath = `/v2/org/{buyer_org_id}/media/signature`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mediaSignatureCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganisationMediaApi - functional programming interface
 * @export
 */
export const OrganisationMediaApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganisationMediaApiAxiosParamCreator(configuration)
    return {
        /**
         * Once archived, a Media item cannot be added to  new fields. If the link already existed on an update then it can continue to be linked
         * @summary Archive the Org Media
         * @param {string} buyerOrgId 
         * @param {string} mediaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgMediaArchiveCreate(buyerOrgId: string, mediaId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrgMedia>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgMediaArchiveCreate(buyerOrgId, mediaId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Unarchive the Org Media
         * @param {string} buyerOrgId 
         * @param {string} mediaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgMediaArchiveDestroy(buyerOrgId: string, mediaId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrgMedia>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgMediaArchiveDestroy(buyerOrgId, mediaId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Searches against the Orgs Media using the following fields: - name (filename) - description  When searching, those which have an exact match of the whole string are first, followed by those which start with the query, and finally those which contain the search param.  Returns the first 10 results 
         * @summary Autocomplete Search
         * @param {string} buyerOrgId 
         * @param {string} [includeArchived] Includes archived Media. values: \&#39;1\&#39; or \&#39;0\&#39;, default: \&#39;0\&#39;
         * @param {string} [q] Query parameter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgMediaAutocompleteList(buyerOrgId: string, includeArchived?: string, q?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrgMediaAutocomplete>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgMediaAutocompleteList(buyerOrgId, includeArchived, q, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Media item
         * @param {string} buyerOrgId 
         * @param {OrgMediaCreate} orgMediaCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgMediaCreate(buyerOrgId: string, orgMediaCreate: OrgMediaCreate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrgMedia>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgMediaCreate(buyerOrgId, orgMediaCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *   # Image transformation  This works on any endpoints below which return the `OrgMediaSerializer`, except /autocomplete.  To generate your own secure URL\'s with custom transformations you need to pass in your transforms via the querystring. Any number can be passed through and the name **MUST** begin with `t__`.  eg `?t__{name}={transformations}>&t__{another name}={transformations 2}`  Where - `{name}` is the key you want it returned as in the `urls` - `{transformations}` is the transformations you want done to the image.  For example, this sample image on Cloudinary we have: https://tx.cloudinary.com/?publicId=docs/blue_sweater&transformationString=a_vflip/e_pixelate_faces:5/c_thumb,w_100   1. cropped it as a thumb with a width of 100 2. flipped it vertically 3. pixelated the faces using pixels 5x5 in size  It results in the transformation url: https://res.cloudinary.com/demo/image/upload/<span style=\"color:red; font-weight: bold;\">a_vflip/e_pixelate_faces:5/c_thumb,w_100</span>/docs/blue_sweater.jpg`  ![Transformed image](https://res.cloudinary.com/demo/image/upload/a_vflip/e_pixelate_faces:5/c_thumb,w_100/docs/blue_sweater.jpg)  The `{transformations}` part we want to use is that in red: `a_vflip/e_pixelate_faces:5/c_thumb,w_100`  and we want to call the transform `flip_the_face` so we\'d pass it value the querystring as:  `?t__flip_the_face=a_vflip/e_pixelate_faces:5/c_thumb,w_100`  This would result in the `urls` key returning the key and URL you can use to display the transformed image.  ``` {     \"urls\": {         \"download\": \"https://...\",         \"original\": \"https://...\",         \"flip_the_face\": \"https://res.cloudinary.com/demo/image/upload/a_vflip/e_pixelate_faces:5/c_thumb,w_100/docs/blue_sweater.jpg\"     } } ``` 
         * @summary Org\'s media
         * @param {string} buyerOrgId 
         * @param {string} [includeArchived] 
         * @param {Array<'-created' | '-name' | 'created' | 'name'>} [ordering] Ordering
         * @param {string} [q] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgMediaList(buyerOrgId: string, includeArchived?: string, ordering?: Array<'-created' | '-name' | 'created' | 'name'>, q?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrgMedia>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgMediaList(buyerOrgId, includeArchived, ordering, q, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Media item
         * @param {string} buyerOrgId 
         * @param {string} mediaId 
         * @param {PatchedOrgMediaUpdate} [patchedOrgMediaUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgMediaPartialUpdate(buyerOrgId: string, mediaId: string, patchedOrgMediaUpdate?: PatchedOrgMediaUpdate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrgMedia>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgMediaPartialUpdate(buyerOrgId, mediaId, patchedOrgMediaUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *   # Image transformation  This works on any endpoints below which return the `OrgMediaSerializer`, except /autocomplete.  To generate your own secure URL\'s with custom transformations you need to pass in your transforms via the querystring. Any number can be passed through and the name **MUST** begin with `t__`.  eg `?t__{name}={transformations}>&t__{another name}={transformations 2}`  Where - `{name}` is the key you want it returned as in the `urls` - `{transformations}` is the transformations you want done to the image.  For example, this sample image on Cloudinary we have: https://tx.cloudinary.com/?publicId=docs/blue_sweater&transformationString=a_vflip/e_pixelate_faces:5/c_thumb,w_100   1. cropped it as a thumb with a width of 100 2. flipped it vertically 3. pixelated the faces using pixels 5x5 in size  It results in the transformation url: https://res.cloudinary.com/demo/image/upload/<span style=\"color:red; font-weight: bold;\">a_vflip/e_pixelate_faces:5/c_thumb,w_100</span>/docs/blue_sweater.jpg`  ![Transformed image](https://res.cloudinary.com/demo/image/upload/a_vflip/e_pixelate_faces:5/c_thumb,w_100/docs/blue_sweater.jpg)  The `{transformations}` part we want to use is that in red: `a_vflip/e_pixelate_faces:5/c_thumb,w_100`  and we want to call the transform `flip_the_face` so we\'d pass it value the querystring as:  `?t__flip_the_face=a_vflip/e_pixelate_faces:5/c_thumb,w_100`  This would result in the `urls` key returning the key and URL you can use to display the transformed image.  ``` {     \"urls\": {         \"download\": \"https://...\",         \"original\": \"https://...\",         \"flip_the_face\": \"https://res.cloudinary.com/demo/image/upload/a_vflip/e_pixelate_faces:5/c_thumb,w_100/docs/blue_sweater.jpg\"     } } ``` 
         * @summary Fetch a specific Media item
         * @param {string} buyerOrgId 
         * @param {string} mediaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgMediaRetrieve(buyerOrgId: string, mediaId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrgMedia>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgMediaRetrieve(buyerOrgId, mediaId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Generates a signature to allow content to be uploaded to Cloudinary directly
         * @param {string} buyerOrgId 
         * @param {MediaSignatureCreate} mediaSignatureCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgMediaSignatureCreate(buyerOrgId: string, mediaSignatureCreate: MediaSignatureCreate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MediaSignature>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgMediaSignatureCreate(buyerOrgId, mediaSignatureCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrganisationMediaApi - factory interface
 * @export
 */
export const OrganisationMediaApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganisationMediaApiFp(configuration)
    return {
        /**
         * Once archived, a Media item cannot be added to  new fields. If the link already existed on an update then it can continue to be linked
         * @summary Archive the Org Media
         * @param {string} buyerOrgId 
         * @param {string} mediaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgMediaArchiveCreate(buyerOrgId: string, mediaId: string, options?: any): AxiosPromise<OrgMedia> {
            return localVarFp.orgMediaArchiveCreate(buyerOrgId, mediaId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Unarchive the Org Media
         * @param {string} buyerOrgId 
         * @param {string} mediaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgMediaArchiveDestroy(buyerOrgId: string, mediaId: string, options?: any): AxiosPromise<OrgMedia> {
            return localVarFp.orgMediaArchiveDestroy(buyerOrgId, mediaId, options).then((request) => request(axios, basePath));
        },
        /**
         * Searches against the Orgs Media using the following fields: - name (filename) - description  When searching, those which have an exact match of the whole string are first, followed by those which start with the query, and finally those which contain the search param.  Returns the first 10 results 
         * @summary Autocomplete Search
         * @param {string} buyerOrgId 
         * @param {string} [includeArchived] Includes archived Media. values: \&#39;1\&#39; or \&#39;0\&#39;, default: \&#39;0\&#39;
         * @param {string} [q] Query parameter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgMediaAutocompleteList(buyerOrgId: string, includeArchived?: string, q?: string, options?: any): AxiosPromise<Array<OrgMediaAutocomplete>> {
            return localVarFp.orgMediaAutocompleteList(buyerOrgId, includeArchived, q, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Media item
         * @param {string} buyerOrgId 
         * @param {OrgMediaCreate} orgMediaCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgMediaCreate(buyerOrgId: string, orgMediaCreate: OrgMediaCreate, options?: any): AxiosPromise<OrgMedia> {
            return localVarFp.orgMediaCreate(buyerOrgId, orgMediaCreate, options).then((request) => request(axios, basePath));
        },
        /**
         *   # Image transformation  This works on any endpoints below which return the `OrgMediaSerializer`, except /autocomplete.  To generate your own secure URL\'s with custom transformations you need to pass in your transforms via the querystring. Any number can be passed through and the name **MUST** begin with `t__`.  eg `?t__{name}={transformations}>&t__{another name}={transformations 2}`  Where - `{name}` is the key you want it returned as in the `urls` - `{transformations}` is the transformations you want done to the image.  For example, this sample image on Cloudinary we have: https://tx.cloudinary.com/?publicId=docs/blue_sweater&transformationString=a_vflip/e_pixelate_faces:5/c_thumb,w_100   1. cropped it as a thumb with a width of 100 2. flipped it vertically 3. pixelated the faces using pixels 5x5 in size  It results in the transformation url: https://res.cloudinary.com/demo/image/upload/<span style=\"color:red; font-weight: bold;\">a_vflip/e_pixelate_faces:5/c_thumb,w_100</span>/docs/blue_sweater.jpg`  ![Transformed image](https://res.cloudinary.com/demo/image/upload/a_vflip/e_pixelate_faces:5/c_thumb,w_100/docs/blue_sweater.jpg)  The `{transformations}` part we want to use is that in red: `a_vflip/e_pixelate_faces:5/c_thumb,w_100`  and we want to call the transform `flip_the_face` so we\'d pass it value the querystring as:  `?t__flip_the_face=a_vflip/e_pixelate_faces:5/c_thumb,w_100`  This would result in the `urls` key returning the key and URL you can use to display the transformed image.  ``` {     \"urls\": {         \"download\": \"https://...\",         \"original\": \"https://...\",         \"flip_the_face\": \"https://res.cloudinary.com/demo/image/upload/a_vflip/e_pixelate_faces:5/c_thumb,w_100/docs/blue_sweater.jpg\"     } } ``` 
         * @summary Org\'s media
         * @param {string} buyerOrgId 
         * @param {string} [includeArchived] 
         * @param {Array<'-created' | '-name' | 'created' | 'name'>} [ordering] Ordering
         * @param {string} [q] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgMediaList(buyerOrgId: string, includeArchived?: string, ordering?: Array<'-created' | '-name' | 'created' | 'name'>, q?: string, options?: any): AxiosPromise<Array<OrgMedia>> {
            return localVarFp.orgMediaList(buyerOrgId, includeArchived, ordering, q, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Media item
         * @param {string} buyerOrgId 
         * @param {string} mediaId 
         * @param {PatchedOrgMediaUpdate} [patchedOrgMediaUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgMediaPartialUpdate(buyerOrgId: string, mediaId: string, patchedOrgMediaUpdate?: PatchedOrgMediaUpdate, options?: any): AxiosPromise<OrgMedia> {
            return localVarFp.orgMediaPartialUpdate(buyerOrgId, mediaId, patchedOrgMediaUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         *   # Image transformation  This works on any endpoints below which return the `OrgMediaSerializer`, except /autocomplete.  To generate your own secure URL\'s with custom transformations you need to pass in your transforms via the querystring. Any number can be passed through and the name **MUST** begin with `t__`.  eg `?t__{name}={transformations}>&t__{another name}={transformations 2}`  Where - `{name}` is the key you want it returned as in the `urls` - `{transformations}` is the transformations you want done to the image.  For example, this sample image on Cloudinary we have: https://tx.cloudinary.com/?publicId=docs/blue_sweater&transformationString=a_vflip/e_pixelate_faces:5/c_thumb,w_100   1. cropped it as a thumb with a width of 100 2. flipped it vertically 3. pixelated the faces using pixels 5x5 in size  It results in the transformation url: https://res.cloudinary.com/demo/image/upload/<span style=\"color:red; font-weight: bold;\">a_vflip/e_pixelate_faces:5/c_thumb,w_100</span>/docs/blue_sweater.jpg`  ![Transformed image](https://res.cloudinary.com/demo/image/upload/a_vflip/e_pixelate_faces:5/c_thumb,w_100/docs/blue_sweater.jpg)  The `{transformations}` part we want to use is that in red: `a_vflip/e_pixelate_faces:5/c_thumb,w_100`  and we want to call the transform `flip_the_face` so we\'d pass it value the querystring as:  `?t__flip_the_face=a_vflip/e_pixelate_faces:5/c_thumb,w_100`  This would result in the `urls` key returning the key and URL you can use to display the transformed image.  ``` {     \"urls\": {         \"download\": \"https://...\",         \"original\": \"https://...\",         \"flip_the_face\": \"https://res.cloudinary.com/demo/image/upload/a_vflip/e_pixelate_faces:5/c_thumb,w_100/docs/blue_sweater.jpg\"     } } ``` 
         * @summary Fetch a specific Media item
         * @param {string} buyerOrgId 
         * @param {string} mediaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgMediaRetrieve(buyerOrgId: string, mediaId: string, options?: any): AxiosPromise<OrgMedia> {
            return localVarFp.orgMediaRetrieve(buyerOrgId, mediaId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Generates a signature to allow content to be uploaded to Cloudinary directly
         * @param {string} buyerOrgId 
         * @param {MediaSignatureCreate} mediaSignatureCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgMediaSignatureCreate(buyerOrgId: string, mediaSignatureCreate: MediaSignatureCreate, options?: any): AxiosPromise<MediaSignature> {
            return localVarFp.orgMediaSignatureCreate(buyerOrgId, mediaSignatureCreate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for orgMediaArchiveCreate operation in OrganisationMediaApi.
 * @export
 * @interface OrganisationMediaApiOrgMediaArchiveCreateRequest
 */
export interface OrganisationMediaApiOrgMediaArchiveCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationMediaApiOrgMediaArchiveCreate
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {string}
     * @memberof OrganisationMediaApiOrgMediaArchiveCreate
     */
    readonly mediaId: string
}

/**
 * Request parameters for orgMediaArchiveDestroy operation in OrganisationMediaApi.
 * @export
 * @interface OrganisationMediaApiOrgMediaArchiveDestroyRequest
 */
export interface OrganisationMediaApiOrgMediaArchiveDestroyRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationMediaApiOrgMediaArchiveDestroy
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {string}
     * @memberof OrganisationMediaApiOrgMediaArchiveDestroy
     */
    readonly mediaId: string
}

/**
 * Request parameters for orgMediaAutocompleteList operation in OrganisationMediaApi.
 * @export
 * @interface OrganisationMediaApiOrgMediaAutocompleteListRequest
 */
export interface OrganisationMediaApiOrgMediaAutocompleteListRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationMediaApiOrgMediaAutocompleteList
     */
    readonly buyerOrgId: string

    /**
     * Includes archived Media. values: \&#39;1\&#39; or \&#39;0\&#39;, default: \&#39;0\&#39;
     * @type {string}
     * @memberof OrganisationMediaApiOrgMediaAutocompleteList
     */
    readonly includeArchived?: string

    /**
     * Query parameter
     * @type {string}
     * @memberof OrganisationMediaApiOrgMediaAutocompleteList
     */
    readonly q?: string
}

/**
 * Request parameters for orgMediaCreate operation in OrganisationMediaApi.
 * @export
 * @interface OrganisationMediaApiOrgMediaCreateRequest
 */
export interface OrganisationMediaApiOrgMediaCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationMediaApiOrgMediaCreate
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {OrgMediaCreate}
     * @memberof OrganisationMediaApiOrgMediaCreate
     */
    readonly orgMediaCreate: OrgMediaCreate
}

/**
 * Request parameters for orgMediaList operation in OrganisationMediaApi.
 * @export
 * @interface OrganisationMediaApiOrgMediaListRequest
 */
export interface OrganisationMediaApiOrgMediaListRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationMediaApiOrgMediaList
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {string}
     * @memberof OrganisationMediaApiOrgMediaList
     */
    readonly includeArchived?: string

    /**
     * Ordering
     * @type {Array<'-created' | '-name' | 'created' | 'name'>}
     * @memberof OrganisationMediaApiOrgMediaList
     */
    readonly ordering?: Array<'-created' | '-name' | 'created' | 'name'>

    /**
     * 
     * @type {string}
     * @memberof OrganisationMediaApiOrgMediaList
     */
    readonly q?: string
}

/**
 * Request parameters for orgMediaPartialUpdate operation in OrganisationMediaApi.
 * @export
 * @interface OrganisationMediaApiOrgMediaPartialUpdateRequest
 */
export interface OrganisationMediaApiOrgMediaPartialUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationMediaApiOrgMediaPartialUpdate
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {string}
     * @memberof OrganisationMediaApiOrgMediaPartialUpdate
     */
    readonly mediaId: string

    /**
     * 
     * @type {PatchedOrgMediaUpdate}
     * @memberof OrganisationMediaApiOrgMediaPartialUpdate
     */
    readonly patchedOrgMediaUpdate?: PatchedOrgMediaUpdate
}

/**
 * Request parameters for orgMediaRetrieve operation in OrganisationMediaApi.
 * @export
 * @interface OrganisationMediaApiOrgMediaRetrieveRequest
 */
export interface OrganisationMediaApiOrgMediaRetrieveRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationMediaApiOrgMediaRetrieve
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {string}
     * @memberof OrganisationMediaApiOrgMediaRetrieve
     */
    readonly mediaId: string
}

/**
 * Request parameters for orgMediaSignatureCreate operation in OrganisationMediaApi.
 * @export
 * @interface OrganisationMediaApiOrgMediaSignatureCreateRequest
 */
export interface OrganisationMediaApiOrgMediaSignatureCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationMediaApiOrgMediaSignatureCreate
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {MediaSignatureCreate}
     * @memberof OrganisationMediaApiOrgMediaSignatureCreate
     */
    readonly mediaSignatureCreate: MediaSignatureCreate
}

/**
 * OrganisationMediaApi - object-oriented interface
 * @export
 * @class OrganisationMediaApi
 * @extends {BaseAPI}
 */
export class OrganisationMediaApi extends BaseAPI {
    /**
     * Once archived, a Media item cannot be added to  new fields. If the link already existed on an update then it can continue to be linked
     * @summary Archive the Org Media
     * @param {OrganisationMediaApiOrgMediaArchiveCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationMediaApi
     */
    public orgMediaArchiveCreate(requestParameters: OrganisationMediaApiOrgMediaArchiveCreateRequest, options?: any) {
        return OrganisationMediaApiFp(this.configuration).orgMediaArchiveCreate(requestParameters.buyerOrgId, requestParameters.mediaId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Unarchive the Org Media
     * @param {OrganisationMediaApiOrgMediaArchiveDestroyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationMediaApi
     */
    public orgMediaArchiveDestroy(requestParameters: OrganisationMediaApiOrgMediaArchiveDestroyRequest, options?: any) {
        return OrganisationMediaApiFp(this.configuration).orgMediaArchiveDestroy(requestParameters.buyerOrgId, requestParameters.mediaId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Searches against the Orgs Media using the following fields: - name (filename) - description  When searching, those which have an exact match of the whole string are first, followed by those which start with the query, and finally those which contain the search param.  Returns the first 10 results 
     * @summary Autocomplete Search
     * @param {OrganisationMediaApiOrgMediaAutocompleteListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationMediaApi
     */
    public orgMediaAutocompleteList(requestParameters: OrganisationMediaApiOrgMediaAutocompleteListRequest, options?: any) {
        return OrganisationMediaApiFp(this.configuration).orgMediaAutocompleteList(requestParameters.buyerOrgId, requestParameters.includeArchived, requestParameters.q, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Media item
     * @param {OrganisationMediaApiOrgMediaCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationMediaApi
     */
    public orgMediaCreate(requestParameters: OrganisationMediaApiOrgMediaCreateRequest, options?: any) {
        return OrganisationMediaApiFp(this.configuration).orgMediaCreate(requestParameters.buyerOrgId, requestParameters.orgMediaCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *   # Image transformation  This works on any endpoints below which return the `OrgMediaSerializer`, except /autocomplete.  To generate your own secure URL\'s with custom transformations you need to pass in your transforms via the querystring. Any number can be passed through and the name **MUST** begin with `t__`.  eg `?t__{name}={transformations}>&t__{another name}={transformations 2}`  Where - `{name}` is the key you want it returned as in the `urls` - `{transformations}` is the transformations you want done to the image.  For example, this sample image on Cloudinary we have: https://tx.cloudinary.com/?publicId=docs/blue_sweater&transformationString=a_vflip/e_pixelate_faces:5/c_thumb,w_100   1. cropped it as a thumb with a width of 100 2. flipped it vertically 3. pixelated the faces using pixels 5x5 in size  It results in the transformation url: https://res.cloudinary.com/demo/image/upload/<span style=\"color:red; font-weight: bold;\">a_vflip/e_pixelate_faces:5/c_thumb,w_100</span>/docs/blue_sweater.jpg`  ![Transformed image](https://res.cloudinary.com/demo/image/upload/a_vflip/e_pixelate_faces:5/c_thumb,w_100/docs/blue_sweater.jpg)  The `{transformations}` part we want to use is that in red: `a_vflip/e_pixelate_faces:5/c_thumb,w_100`  and we want to call the transform `flip_the_face` so we\'d pass it value the querystring as:  `?t__flip_the_face=a_vflip/e_pixelate_faces:5/c_thumb,w_100`  This would result in the `urls` key returning the key and URL you can use to display the transformed image.  ``` {     \"urls\": {         \"download\": \"https://...\",         \"original\": \"https://...\",         \"flip_the_face\": \"https://res.cloudinary.com/demo/image/upload/a_vflip/e_pixelate_faces:5/c_thumb,w_100/docs/blue_sweater.jpg\"     } } ``` 
     * @summary Org\'s media
     * @param {OrganisationMediaApiOrgMediaListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationMediaApi
     */
    public orgMediaList(requestParameters: OrganisationMediaApiOrgMediaListRequest, options?: any) {
        return OrganisationMediaApiFp(this.configuration).orgMediaList(requestParameters.buyerOrgId, requestParameters.includeArchived, requestParameters.ordering, requestParameters.q, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Media item
     * @param {OrganisationMediaApiOrgMediaPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationMediaApi
     */
    public orgMediaPartialUpdate(requestParameters: OrganisationMediaApiOrgMediaPartialUpdateRequest, options?: any) {
        return OrganisationMediaApiFp(this.configuration).orgMediaPartialUpdate(requestParameters.buyerOrgId, requestParameters.mediaId, requestParameters.patchedOrgMediaUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *   # Image transformation  This works on any endpoints below which return the `OrgMediaSerializer`, except /autocomplete.  To generate your own secure URL\'s with custom transformations you need to pass in your transforms via the querystring. Any number can be passed through and the name **MUST** begin with `t__`.  eg `?t__{name}={transformations}>&t__{another name}={transformations 2}`  Where - `{name}` is the key you want it returned as in the `urls` - `{transformations}` is the transformations you want done to the image.  For example, this sample image on Cloudinary we have: https://tx.cloudinary.com/?publicId=docs/blue_sweater&transformationString=a_vflip/e_pixelate_faces:5/c_thumb,w_100   1. cropped it as a thumb with a width of 100 2. flipped it vertically 3. pixelated the faces using pixels 5x5 in size  It results in the transformation url: https://res.cloudinary.com/demo/image/upload/<span style=\"color:red; font-weight: bold;\">a_vflip/e_pixelate_faces:5/c_thumb,w_100</span>/docs/blue_sweater.jpg`  ![Transformed image](https://res.cloudinary.com/demo/image/upload/a_vflip/e_pixelate_faces:5/c_thumb,w_100/docs/blue_sweater.jpg)  The `{transformations}` part we want to use is that in red: `a_vflip/e_pixelate_faces:5/c_thumb,w_100`  and we want to call the transform `flip_the_face` so we\'d pass it value the querystring as:  `?t__flip_the_face=a_vflip/e_pixelate_faces:5/c_thumb,w_100`  This would result in the `urls` key returning the key and URL you can use to display the transformed image.  ``` {     \"urls\": {         \"download\": \"https://...\",         \"original\": \"https://...\",         \"flip_the_face\": \"https://res.cloudinary.com/demo/image/upload/a_vflip/e_pixelate_faces:5/c_thumb,w_100/docs/blue_sweater.jpg\"     } } ``` 
     * @summary Fetch a specific Media item
     * @param {OrganisationMediaApiOrgMediaRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationMediaApi
     */
    public orgMediaRetrieve(requestParameters: OrganisationMediaApiOrgMediaRetrieveRequest, options?: any) {
        return OrganisationMediaApiFp(this.configuration).orgMediaRetrieve(requestParameters.buyerOrgId, requestParameters.mediaId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Generates a signature to allow content to be uploaded to Cloudinary directly
     * @param {OrganisationMediaApiOrgMediaSignatureCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationMediaApi
     */
    public orgMediaSignatureCreate(requestParameters: OrganisationMediaApiOrgMediaSignatureCreateRequest, options?: any) {
        return OrganisationMediaApiFp(this.configuration).orgMediaSignatureCreate(requestParameters.buyerOrgId, requestParameters.mediaSignatureCreate, options).then((request) => request(this.axios, this.basePath));
    }
}
