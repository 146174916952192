export { ReactComponent as AbnIcon } from './abn.svg';
export { ReactComponent as AddCircleIcon } from './add-circle.svg';
export { ReactComponent as AddToGroupIcon } from './add-to-group.svg';
export { ReactComponent as AddContact } from './add-contact.svg';
export { ReactComponent as AddIcon } from './add.svg';
export { ReactComponent as AgoraLogoWithText } from './agora-logo-with-text.svg';
export { ReactComponent as AgoraLogo } from './agora-logo.svg';
export { ReactComponent as AlertIcon } from './alert.svg';
export { ReactComponent as AmexIcon } from './amex.svg';
export { ReactComponent as ArrowDownIcon } from './arrow-down.svg';
export { ReactComponent as ArrowLeftIcon } from './arrow-left.svg';
export { ReactComponent as ArrowRightIcon } from './arrow-right.svg';
export { ReactComponent as ArrowUpIcon } from './arrow-up.svg';
export { ReactComponent as BriefcaseIcon } from './briefcase.svg';
export { ReactComponent as BusinessIcon } from './business.svg';
export { ReactComponent as Calendar1Icon } from './calendar-1.svg';
export { ReactComponent as Calendar2Icon } from './calendar-2.svg';
export { ReactComponent as CameraIcon } from './camera.svg';
export { ReactComponent as CancelCircleIcon } from './cancel-circle.svg';
export { ReactComponent as CancelIcon } from './cancel.svg';
export { ReactComponent as CattleIcon } from './cattle.svg';
export { ReactComponent as ChangeIcon } from './change.svg';
export { ReactComponent as CheckCircleIcon } from './check-circle.svg';
export { ReactComponent as CheckGreenCircleIcon } from './check-green-circle.svg';
export { ReactComponent as CheckIcon } from './check.svg';
export { ReactComponent as ChevronBackIcon } from './chevron-back.svg';
export { ReactComponent as ChevronDownIcon } from './chevron-down.svg';
export { ReactComponent as ChevronNextIcon } from './chevron-next.svg';
export { ReactComponent as ChevronUpIcon } from './chevron-up.svg';
export { ReactComponent as CircleIcon } from './circle.svg';
export { ReactComponent as CloudDownloadIcon } from './cloud-download.svg';
export { ReactComponent as CloudUploadIcon } from './cloud-upload.svg';
export { ReactComponent as CloudIcon } from './cloud.svg';
export { ReactComponent as CommentIcon } from './comment.svg';
export { ReactComponent as ContactsIcon } from './contacts.svg';
export { ReactComponent as ContractIcon } from './contract.svg';
export { ReactComponent as CSVIcon } from './csv.svg';
export { ReactComponent as DocxIcon } from './docx.svg';
export { ReactComponent as DollarSignIcon } from './dollar-sign.svg';
export { ReactComponent as DollarIcon } from './dollar.svg';
export { ReactComponent as DownloadIcon } from './download.svg';
export { ReactComponent as DuplicateIcon } from './duplicate.svg';
export { ReactComponent as EditIcon } from './edit.svg';
export { ReactComponent as EmailIcon } from './email.svg';
export { ReactComponent as ExpandIcon } from './expand.svg';
export { ReactComponent as EyeHideIcon } from './eye-hide.svg';
export { ReactComponent as EyeIcon } from './eye.svg';
export { ReactComponent as FacebookIcon } from './facebook.svg';
export { ReactComponent as FastForwardIcon } from './fast-forward.svg';
export { ReactComponent as FilterIcon } from './filter.svg';
export { ReactComponent as GoatIcon } from './goat.svg';
export { ReactComponent as GridWizardIcon } from './grid-wizard.svg';
export { ReactComponent as HeartIcon } from './heart.svg';
export { ReactComponent as HelpCircleIcon } from './help-circle.svg';
export { ReactComponent as HelpIcon } from './help.svg';
export { ReactComponent as HutIcon } from './hut.svg';
export { ReactComponent as IncomingIcon } from './incoming-icon.svg';
export { ReactComponent as JCBIcon } from './jcb.svg';
export { ReactComponent as KebabIcon } from './kebab.svg';
export { ReactComponent as LeftArrowIcon } from './left-arrow.svg';
export { ReactComponent as LeftFilledArrowIcon } from './left-filled-arrow.svg';
export { ReactComponent as LinkIcon } from './link.svg';
export { ReactComponent as LoadingIcon } from './loading.svg';
export { ReactComponent as LogoSolidIcon } from './logo-solid.svg';
export { ReactComponent as LogoutIcon } from './logout.svg';
export { ReactComponent as MapPinIcon } from './map-pin.svg';
export { ReactComponent as Marketplace } from './marketplace.svg';
export { ReactComponent as MarketplaceAlt } from './marketplaceAlt.svg';
export { ReactComponent as MastercardIcon } from './mastercard.svg';
export { ReactComponent as MaximizeIcon } from './maximize.svg';
export { ReactComponent as MegaphoneIcon } from './megaphone.svg';
export { ReactComponent as MenuIcon } from './menu.svg';
export { ReactComponent as MediaIcon } from './media.svg';
export { ReactComponent as MinimizeIcon } from './minimize.svg';
export { ReactComponent as MinusCircleIcon } from './minus-circle.svg';
export { ReactComponent as MinusIcon } from './minus.svg';
export { ReactComponent as MyListingsIcon } from './my-listings.svg';
export { ReactComponent as NetworkIcon } from './network.svg';
export { ReactComponent as NewsIcon } from './news.svg';
export { ReactComponent as NotificationIcon } from './notification.svg';
export { ReactComponent as OffersIcon } from './offers.svg';
export { ReactComponent as OutgoingIcon } from './out-going.svg';
export { ReactComponent as PadlockIcon } from './padlock.svg';
export { ReactComponent as PageIcon } from './page.svg';
export { ReactComponent as PaperAirplaneIcon } from './paper-airplane.svg';
export { ReactComponent as PaperClipIcon } from './paper-clip.svg';
export { ReactComponent as PDFIcon } from './pdf.svg';
export { ReactComponent as PeopleIcon } from './people.svg';
export { ReactComponent as PhoneBookIcon } from './phone-book.svg';
export { ReactComponent as PhoneIcon } from './phone.svg';
export { ReactComponent as PictureIcon } from './picture.svg';
export { ReactComponent as PlayIcon } from './play.svg';
export { ReactComponent as PlusIcon } from './plus.svg';
export { ReactComponent as PreviewFavouriteIcon } from './preview-favourite.svg';
export { ReactComponent as PriceTagIcon } from './price-tag.svg';
export { ReactComponent as ProfileIcon } from './profile.svg';
export { ReactComponent as RedoIcon } from './redo.svg';
export { ReactComponent as RightArrowIcon } from './right-arrow.svg';
export { ReactComponent as RightFilledArrowIcon } from './right-filled-arrow.svg';
export { ReactComponent as ScaleIcon } from './scale-icon.svg';
export { ReactComponent as SearchIcon } from './search.svg';
export { ReactComponent as Settings1Icon } from './settings-1.svg';
export { ReactComponent as Settings2Icon } from './settings-2.svg';
export { ReactComponent as ShareLinkIcon } from './share-link.svg';
export { ReactComponent as ShareIcon } from './share.svg';
export { ReactComponent as SheepIcon } from './sheep.svg';
export { ReactComponent as StarIcon } from './star.svg';
export { ReactComponent as StockbookNewIcon } from './stockbook-new.svg';
export { ReactComponent as StockbookIcon } from './stockbook.svg';
export { ReactComponent as StopIcon } from './stop.svg';
export { ReactComponent as SummaryGridIcon } from './summary-grid.svg';
export { ReactComponent as TagIcon } from './tag.svg';
export { ReactComponent as TrashIcon } from './trash.svg';
export { ReactComponent as TruckIcon } from './truck.svg';
export { ReactComponent as TwitterIcon } from './twitter.svg';
export { ReactComponent as UnionPayIcon } from './unionpay.svg';
export { ReactComponent as UpdatingIcon } from './updating.svg';
export { ReactComponent as AgoraUserIcon } from './user-agora.svg';
export { ReactComponent as UserIcon } from './user.svg';
export { ReactComponent as VisaIcon } from './visa.svg';
export { ReactComponent as XlsxIcon } from './xlsx.svg';
export { ReactComponent as ZoomInIcon } from './zoom-in.svg';
export { ReactComponent as ZoomOutIcon } from './zoom-out.svg';
export { ReactComponent as PicManagerIcon } from './pic-manager.svg';
export { ReactComponent as CrossIcon } from './cross.svg';
export { ReactComponent as TickIcon } from './tick.svg';
export { ReactComponent as LineIcon } from './line.svg';
export { ReactComponent as StockRequiredIcon } from './stock-required.svg';
export { ReactComponent as LockIcon } from './lock.svg';
export { ReactComponent as UnlockIcon } from './unlock.svg';
export { ReactComponent as CheckCircleSolid } from './check-circle-solid.svg';
export { ReactComponent as CancelCircleSolid } from './cancel-circle-solid.svg';
export { ReactComponent as AlertCircleSolid } from './alert-circle-solid.svg';
export { ReactComponent as IntercomIcon } from './intercom-launcher-icon.svg';
export { ReactComponent as MarketplaceNotificationIcon } from './marketplace-notification-icon.svg';
export { ReactComponent as MarketplaceNotificationIconActive } from './marketplace-notification-active.svg';
export { ReactComponent as MarketplaceNotificationIconMuted } from './marketplace-notification-muted.svg';
export { ReactComponent as ChatIcon } from './chat.svg';
export { ReactComponent as ClockIcon } from './clock.svg';
export { ReactComponent as CircleEditIcon } from './edit-circle-icon.svg';
export { ReactComponent as NewWindow } from './new-window.svg';
export { ReactComponent as ImageIcon } from './image.svg';

// Confirmation icons
export { ReactComponent as ConfirmationAccepted } from './confirmation-accepted.svg';
export { ReactComponent as ConfirmationOutgoing } from './outgoing-confirmation.svg';
export { ReactComponent as ConfirmationIncoming } from './confirmation-incoming.svg';
export { ReactComponent as ConfirmationDeclined } from './confirmation-declined.svg';
export { ReactComponent as ConfirmationOutgoingCancelled } from './confirmation-outgoing-cancel.svg';
export { ReactComponent as ConfirmationIncomingCancelled } from './confirmation-incoming-cancel.svg';
export { ReactComponent as ConfirmationUnsent } from './confirmation-unsent.svg';
export { ReactComponent as SafariBrowser } from './safari.svg';
export { ReactComponent as ShareIos } from './share-ios.svg';
export { ReactComponent as AddSquare } from './add-square.svg';
