/* tslint:disable */
/* eslint-disable */
/**
 * Agora Livestock API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0 (v2)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { APIException } from '../model';
// @ts-ignore
import { BookingConfirmationPublic } from '../model';
// @ts-ignore
import { BookingConfirmationPublicAccept } from '../model';
// @ts-ignore
import { BookingConfirmationPublicCreateMedia } from '../model';
// @ts-ignore
import { BookingConfirmationPublicMediaRead } from '../model';
// @ts-ignore
import { BookingConfirmationPublicMediaSignature } from '../model';
// @ts-ignore
import { ConfirmationAcceptedPublic } from '../model';
// @ts-ignore
import { MediaSignature } from '../model';
// @ts-ignore
import { ValidationError } from '../model';
/**
 * PublicBookingConfirmationApi - axios parameter creator
 * @export
 */
export const PublicBookingConfirmationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Acceptance can only occur when the Confirmation is the latest for the Booking and the status of the Confirmation is OPEN. If theres any issue when accepting the Confirmation the message for why will be returned in the `detail` key in the body.  An org id can be passed through which will create a linked Booking on the Org. Once this is set on a Booking via any of the Confirmation acceptances, it cannot be set again. time for any Confirm  It returns the SMS link to redirect the User to.
         * @summary Accepts the Booking
         * @param {string} confirmationId 
         * @param {BookingConfirmationPublicAccept} [bookingConfirmationPublicAccept] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicBookingConfirmApproveCreate: async (confirmationId: string, bookingConfirmationPublicAccept?: BookingConfirmationPublicAccept, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'confirmationId' is not null or undefined
            assertParamExists('publicBookingConfirmApproveCreate', 'confirmationId', confirmationId)
            const localVarPath = `/v2/public/booking/confirm/{confirmation_id}/approve`
                .replace(`{${"confirmation_id"}}`, encodeURIComponent(String(confirmationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bookingConfirmationPublicAccept, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Declining a Confirmation can only occur when the Confirmation is the latest for the Booking and the status of the Confirmation is OPEN.  If theres any issue when accepting the Confirmation the message for why will be returned in the `detail` key in the body.
         * @summary Declines the Booking
         * @param {string} confirmationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicBookingConfirmDeclineCreate: async (confirmationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'confirmationId' is not null or undefined
            assertParamExists('publicBookingConfirmDeclineCreate', 'confirmationId', confirmationId)
            const localVarPath = `/v2/public/booking/confirm/{confirmation_id}/decline`
                .replace(`{${"confirmation_id"}}`, encodeURIComponent(String(confirmationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Uploads media against the Confirmation
         * @param {string} confirmationId 
         * @param {BookingConfirmationPublicCreateMedia} bookingConfirmationPublicCreateMedia 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicBookingConfirmMediaCreate: async (confirmationId: string, bookingConfirmationPublicCreateMedia: BookingConfirmationPublicCreateMedia, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'confirmationId' is not null or undefined
            assertParamExists('publicBookingConfirmMediaCreate', 'confirmationId', confirmationId)
            // verify required parameter 'bookingConfirmationPublicCreateMedia' is not null or undefined
            assertParamExists('publicBookingConfirmMediaCreate', 'bookingConfirmationPublicCreateMedia', bookingConfirmationPublicCreateMedia)
            const localVarPath = `/v2/public/booking/confirm/{confirmation_id}/media`
                .replace(`{${"confirmation_id"}}`, encodeURIComponent(String(confirmationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bookingConfirmationPublicCreateMedia, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generates a signature to allow content to be uploaded to Cloudinary directly
         * @param {string} confirmationId 
         * @param {BookingConfirmationPublicMediaSignature} bookingConfirmationPublicMediaSignature 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicBookingConfirmMediaSignatureCreate: async (confirmationId: string, bookingConfirmationPublicMediaSignature: BookingConfirmationPublicMediaSignature, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'confirmationId' is not null or undefined
            assertParamExists('publicBookingConfirmMediaSignatureCreate', 'confirmationId', confirmationId)
            // verify required parameter 'bookingConfirmationPublicMediaSignature' is not null or undefined
            assertParamExists('publicBookingConfirmMediaSignatureCreate', 'bookingConfirmationPublicMediaSignature', bookingConfirmationPublicMediaSignature)
            const localVarPath = `/v2/public/booking/confirm/{confirmation_id}/media/signature`
                .replace(`{${"confirmation_id"}}`, encodeURIComponent(String(confirmationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bookingConfirmationPublicMediaSignature, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * `settings.orgs` contains a list of Organisations the Contact is an APPROVED member of. These can be used to assign and Org to the Confirmation on acceptance. 
         * @summary Fetch the public information about the Booking Confirmation
         * @param {string} confirmationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicBookingConfirmRetrieve: async (confirmationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'confirmationId' is not null or undefined
            assertParamExists('publicBookingConfirmRetrieve', 'confirmationId', confirmationId)
            const localVarPath = `/v2/public/booking/confirm/{confirmation_id}`
                .replace(`{${"confirmation_id"}}`, encodeURIComponent(String(confirmationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PublicBookingConfirmationApi - functional programming interface
 * @export
 */
export const PublicBookingConfirmationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PublicBookingConfirmationApiAxiosParamCreator(configuration)
    return {
        /**
         * Acceptance can only occur when the Confirmation is the latest for the Booking and the status of the Confirmation is OPEN. If theres any issue when accepting the Confirmation the message for why will be returned in the `detail` key in the body.  An org id can be passed through which will create a linked Booking on the Org. Once this is set on a Booking via any of the Confirmation acceptances, it cannot be set again. time for any Confirm  It returns the SMS link to redirect the User to.
         * @summary Accepts the Booking
         * @param {string} confirmationId 
         * @param {BookingConfirmationPublicAccept} [bookingConfirmationPublicAccept] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicBookingConfirmApproveCreate(confirmationId: string, bookingConfirmationPublicAccept?: BookingConfirmationPublicAccept, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConfirmationAcceptedPublic>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicBookingConfirmApproveCreate(confirmationId, bookingConfirmationPublicAccept, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Declining a Confirmation can only occur when the Confirmation is the latest for the Booking and the status of the Confirmation is OPEN.  If theres any issue when accepting the Confirmation the message for why will be returned in the `detail` key in the body.
         * @summary Declines the Booking
         * @param {string} confirmationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicBookingConfirmDeclineCreate(confirmationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingConfirmationPublic>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicBookingConfirmDeclineCreate(confirmationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Uploads media against the Confirmation
         * @param {string} confirmationId 
         * @param {BookingConfirmationPublicCreateMedia} bookingConfirmationPublicCreateMedia 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicBookingConfirmMediaCreate(confirmationId: string, bookingConfirmationPublicCreateMedia: BookingConfirmationPublicCreateMedia, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingConfirmationPublicMediaRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicBookingConfirmMediaCreate(confirmationId, bookingConfirmationPublicCreateMedia, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Generates a signature to allow content to be uploaded to Cloudinary directly
         * @param {string} confirmationId 
         * @param {BookingConfirmationPublicMediaSignature} bookingConfirmationPublicMediaSignature 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicBookingConfirmMediaSignatureCreate(confirmationId: string, bookingConfirmationPublicMediaSignature: BookingConfirmationPublicMediaSignature, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MediaSignature>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicBookingConfirmMediaSignatureCreate(confirmationId, bookingConfirmationPublicMediaSignature, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * `settings.orgs` contains a list of Organisations the Contact is an APPROVED member of. These can be used to assign and Org to the Confirmation on acceptance. 
         * @summary Fetch the public information about the Booking Confirmation
         * @param {string} confirmationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicBookingConfirmRetrieve(confirmationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingConfirmationPublic>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicBookingConfirmRetrieve(confirmationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PublicBookingConfirmationApi - factory interface
 * @export
 */
export const PublicBookingConfirmationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PublicBookingConfirmationApiFp(configuration)
    return {
        /**
         * Acceptance can only occur when the Confirmation is the latest for the Booking and the status of the Confirmation is OPEN. If theres any issue when accepting the Confirmation the message for why will be returned in the `detail` key in the body.  An org id can be passed through which will create a linked Booking on the Org. Once this is set on a Booking via any of the Confirmation acceptances, it cannot be set again. time for any Confirm  It returns the SMS link to redirect the User to.
         * @summary Accepts the Booking
         * @param {string} confirmationId 
         * @param {BookingConfirmationPublicAccept} [bookingConfirmationPublicAccept] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicBookingConfirmApproveCreate(confirmationId: string, bookingConfirmationPublicAccept?: BookingConfirmationPublicAccept, options?: any): AxiosPromise<ConfirmationAcceptedPublic> {
            return localVarFp.publicBookingConfirmApproveCreate(confirmationId, bookingConfirmationPublicAccept, options).then((request) => request(axios, basePath));
        },
        /**
         * Declining a Confirmation can only occur when the Confirmation is the latest for the Booking and the status of the Confirmation is OPEN.  If theres any issue when accepting the Confirmation the message for why will be returned in the `detail` key in the body.
         * @summary Declines the Booking
         * @param {string} confirmationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicBookingConfirmDeclineCreate(confirmationId: string, options?: any): AxiosPromise<BookingConfirmationPublic> {
            return localVarFp.publicBookingConfirmDeclineCreate(confirmationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Uploads media against the Confirmation
         * @param {string} confirmationId 
         * @param {BookingConfirmationPublicCreateMedia} bookingConfirmationPublicCreateMedia 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicBookingConfirmMediaCreate(confirmationId: string, bookingConfirmationPublicCreateMedia: BookingConfirmationPublicCreateMedia, options?: any): AxiosPromise<BookingConfirmationPublicMediaRead> {
            return localVarFp.publicBookingConfirmMediaCreate(confirmationId, bookingConfirmationPublicCreateMedia, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Generates a signature to allow content to be uploaded to Cloudinary directly
         * @param {string} confirmationId 
         * @param {BookingConfirmationPublicMediaSignature} bookingConfirmationPublicMediaSignature 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicBookingConfirmMediaSignatureCreate(confirmationId: string, bookingConfirmationPublicMediaSignature: BookingConfirmationPublicMediaSignature, options?: any): AxiosPromise<MediaSignature> {
            return localVarFp.publicBookingConfirmMediaSignatureCreate(confirmationId, bookingConfirmationPublicMediaSignature, options).then((request) => request(axios, basePath));
        },
        /**
         * `settings.orgs` contains a list of Organisations the Contact is an APPROVED member of. These can be used to assign and Org to the Confirmation on acceptance. 
         * @summary Fetch the public information about the Booking Confirmation
         * @param {string} confirmationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicBookingConfirmRetrieve(confirmationId: string, options?: any): AxiosPromise<BookingConfirmationPublic> {
            return localVarFp.publicBookingConfirmRetrieve(confirmationId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for publicBookingConfirmApproveCreate operation in PublicBookingConfirmationApi.
 * @export
 * @interface PublicBookingConfirmationApiPublicBookingConfirmApproveCreateRequest
 */
export interface PublicBookingConfirmationApiPublicBookingConfirmApproveCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof PublicBookingConfirmationApiPublicBookingConfirmApproveCreate
     */
    readonly confirmationId: string

    /**
     * 
     * @type {BookingConfirmationPublicAccept}
     * @memberof PublicBookingConfirmationApiPublicBookingConfirmApproveCreate
     */
    readonly bookingConfirmationPublicAccept?: BookingConfirmationPublicAccept
}

/**
 * Request parameters for publicBookingConfirmDeclineCreate operation in PublicBookingConfirmationApi.
 * @export
 * @interface PublicBookingConfirmationApiPublicBookingConfirmDeclineCreateRequest
 */
export interface PublicBookingConfirmationApiPublicBookingConfirmDeclineCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof PublicBookingConfirmationApiPublicBookingConfirmDeclineCreate
     */
    readonly confirmationId: string
}

/**
 * Request parameters for publicBookingConfirmMediaCreate operation in PublicBookingConfirmationApi.
 * @export
 * @interface PublicBookingConfirmationApiPublicBookingConfirmMediaCreateRequest
 */
export interface PublicBookingConfirmationApiPublicBookingConfirmMediaCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof PublicBookingConfirmationApiPublicBookingConfirmMediaCreate
     */
    readonly confirmationId: string

    /**
     * 
     * @type {BookingConfirmationPublicCreateMedia}
     * @memberof PublicBookingConfirmationApiPublicBookingConfirmMediaCreate
     */
    readonly bookingConfirmationPublicCreateMedia: BookingConfirmationPublicCreateMedia
}

/**
 * Request parameters for publicBookingConfirmMediaSignatureCreate operation in PublicBookingConfirmationApi.
 * @export
 * @interface PublicBookingConfirmationApiPublicBookingConfirmMediaSignatureCreateRequest
 */
export interface PublicBookingConfirmationApiPublicBookingConfirmMediaSignatureCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof PublicBookingConfirmationApiPublicBookingConfirmMediaSignatureCreate
     */
    readonly confirmationId: string

    /**
     * 
     * @type {BookingConfirmationPublicMediaSignature}
     * @memberof PublicBookingConfirmationApiPublicBookingConfirmMediaSignatureCreate
     */
    readonly bookingConfirmationPublicMediaSignature: BookingConfirmationPublicMediaSignature
}

/**
 * Request parameters for publicBookingConfirmRetrieve operation in PublicBookingConfirmationApi.
 * @export
 * @interface PublicBookingConfirmationApiPublicBookingConfirmRetrieveRequest
 */
export interface PublicBookingConfirmationApiPublicBookingConfirmRetrieveRequest {
    /**
     * 
     * @type {string}
     * @memberof PublicBookingConfirmationApiPublicBookingConfirmRetrieve
     */
    readonly confirmationId: string
}

/**
 * PublicBookingConfirmationApi - object-oriented interface
 * @export
 * @class PublicBookingConfirmationApi
 * @extends {BaseAPI}
 */
export class PublicBookingConfirmationApi extends BaseAPI {
    /**
     * Acceptance can only occur when the Confirmation is the latest for the Booking and the status of the Confirmation is OPEN. If theres any issue when accepting the Confirmation the message for why will be returned in the `detail` key in the body.  An org id can be passed through which will create a linked Booking on the Org. Once this is set on a Booking via any of the Confirmation acceptances, it cannot be set again. time for any Confirm  It returns the SMS link to redirect the User to.
     * @summary Accepts the Booking
     * @param {PublicBookingConfirmationApiPublicBookingConfirmApproveCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicBookingConfirmationApi
     */
    public publicBookingConfirmApproveCreate(requestParameters: PublicBookingConfirmationApiPublicBookingConfirmApproveCreateRequest, options?: any) {
        return PublicBookingConfirmationApiFp(this.configuration).publicBookingConfirmApproveCreate(requestParameters.confirmationId, requestParameters.bookingConfirmationPublicAccept, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Declining a Confirmation can only occur when the Confirmation is the latest for the Booking and the status of the Confirmation is OPEN.  If theres any issue when accepting the Confirmation the message for why will be returned in the `detail` key in the body.
     * @summary Declines the Booking
     * @param {PublicBookingConfirmationApiPublicBookingConfirmDeclineCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicBookingConfirmationApi
     */
    public publicBookingConfirmDeclineCreate(requestParameters: PublicBookingConfirmationApiPublicBookingConfirmDeclineCreateRequest, options?: any) {
        return PublicBookingConfirmationApiFp(this.configuration).publicBookingConfirmDeclineCreate(requestParameters.confirmationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Uploads media against the Confirmation
     * @param {PublicBookingConfirmationApiPublicBookingConfirmMediaCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicBookingConfirmationApi
     */
    public publicBookingConfirmMediaCreate(requestParameters: PublicBookingConfirmationApiPublicBookingConfirmMediaCreateRequest, options?: any) {
        return PublicBookingConfirmationApiFp(this.configuration).publicBookingConfirmMediaCreate(requestParameters.confirmationId, requestParameters.bookingConfirmationPublicCreateMedia, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Generates a signature to allow content to be uploaded to Cloudinary directly
     * @param {PublicBookingConfirmationApiPublicBookingConfirmMediaSignatureCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicBookingConfirmationApi
     */
    public publicBookingConfirmMediaSignatureCreate(requestParameters: PublicBookingConfirmationApiPublicBookingConfirmMediaSignatureCreateRequest, options?: any) {
        return PublicBookingConfirmationApiFp(this.configuration).publicBookingConfirmMediaSignatureCreate(requestParameters.confirmationId, requestParameters.bookingConfirmationPublicMediaSignature, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * `settings.orgs` contains a list of Organisations the Contact is an APPROVED member of. These can be used to assign and Org to the Confirmation on acceptance. 
     * @summary Fetch the public information about the Booking Confirmation
     * @param {PublicBookingConfirmationApiPublicBookingConfirmRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicBookingConfirmationApi
     */
    public publicBookingConfirmRetrieve(requestParameters: PublicBookingConfirmationApiPublicBookingConfirmRetrieveRequest, options?: any) {
        return PublicBookingConfirmationApiFp(this.configuration).publicBookingConfirmRetrieve(requestParameters.confirmationId, options).then((request) => request(this.axios, this.basePath));
    }
}
