import { Cloudinary } from '@cloudinary/url-gen';

export const AGORA_CONTACT = '1300812345';

export const SETTINGS = {
  seo: {
    defaultTitle: process.env.NEXT_PUBLIC_NAME,
    defaultDescription: '',
    defaultImage: '',
    twitterSite: '',
  },
};

export const DATE_FORMAT = {
  /** `dd/MM/yyyy` */
  long: 'dd/MM/yyyy',
  /** `d/M/yy` */
  short: 'd/M/yy',
  /**`d MMM */
  xShort: 'd MMM',
  /** 1 Jun 2023 */
  listing: 'd LLL yyyy',
  listing_short: 'd LLL',
  /** TUE, 27 JUN */
  stockbook_week: 'EEE, d MMM',
};

export const DATETIME_FORMAT = {
  /** `dd/MM/yyyy HH:mm:ss` */
  long: 'dd/MM/yyyy HH:mm:ss',
  /** `dd/MM/yyyy HH:mm` */
  medium: 'dd/MM/yyyy HH:mm',
  /** `d/M/yy H:mm` */
  short: 'd/M/yy H:mm',
  /** yeah I dunno */
  extraLong: 'h:mmaaa do LLLL yyyy',
};

export const PATHS = {
  home: '/',
  contacts: {
    home: '/contacts',
  },
  privateGroups: {
    home: '/networks',
  },
  prices: {
    home: '/prices',
    edit: '/prices/edit',
    new: '/prices/edit/new',
  },
  summaryGrids: {
    home: '/summary-grids',
    edit: '/summary-grids/edit',
    new: '/summary-grids/edit/new',
  },
  offers: {
    home: '/offers',
    edit: '/offers/edit',
    new: '/offers/edit/new',
  },
  stockbook: {
    home: '/stockbook',
    contract: '/stockbook/contract',
    orgConfirmation: '/booking/org-confirmation/',
    bookingHistory: '/booking/history/',
  },
  picManager: {
    home: '/pic-manager',
  },
  stockRequired: {
    home: '/stockbook-required',
  },

  help: 'http://www.agoralivestock.com.au/faqs',
  auth: {
    logIn: '/auth/login',
    error: '/auth/error',
    logInVerified: '/auth/login?verified=true',
    logInNotVerified: '/auth/login?verified=false',
    signUp: '/auth/sign-up',
    verification: '/auth/verification',
    forgotPassword: '/auth/forgot-password',
    forgotPasswordConfirmation: '/auth/forgot-password-confirmation',
    resetPasswordConfirmation: '/auth/reset-password-confirmation',
  },
  api: {
    invalidateRefreshToken: '/api/auth/invalidate-refresh-token',
  },
  organisation: {
    picker: '/organisation',
    create: '/organisation/create',
    profile: '/organisation/profile',
  },
  profile: { home: '/profile' },
  marketplace: {
    listings: '/marketplace',
    buyListings: '/marketplace/my-buy-listings',
    sellListings: '/marketplace/my-sell-listings',
    publicListings: '/marketplace/listing',
    publicListing: '/marketplace/listing/[id]',
    viewListings: '/marketplace/listing/view',
    createListing: '/marketplace/listing/edit/new',
    editListing: '/marketplace/listing/edit/',
    listingConfirmation: '/marketplace/listing/listings-confirmation',
    alert: '/marketplace?alertModal=true',
    listing: '/marketplace/listing/',
  },
  media: {
    home: '/media-library',
  },
  app: {
    marketplace: '/app/marketplace',
    alerts: '/app/marketplace-alerts',
    mySellListings: '/app/marketplace/my-sell-listings',
    myBuyListings: '/app/marketplace/my-buy-listings',
    listing: '/app/marketplace/listing',
    viewListing: '/app/marketplace/listing/view',
    addContact: '/app/contacts/add-contact',
  },
};

// Paths that are deemed safe to visit when a buyer org has not yet been selected
export const SAFE_PATHS_NO_ORG = [
  PATHS.profile.home,
  PATHS.organisation.picker,
  PATHS.organisation.create,
  PATHS.marketplace.listings,
  PATHS.marketplace.publicListing,
];

// It's actually 15 minutes but we're refreshing more frequently to ensure the token is always up to date
export const TOKEN_EXPIRY_MINUTES = 5;

export const HEADER_OVERRIDE_PORTAL_ID = 'headerOverrideTarget';

export const PRICE_GRID_EDIT_SECTION_TITLES = {
  livestockType: 'Type of livestock',
  priceAndDelivery: 'Price & delivery information',
  conditions: 'Conditions of purchase',
  livestockSpecs: 'Livestock specifications',
  priceTable: 'Price grid',
};

export const OFFERS_EDIT_SECTION_TITLES = {
  details: 'Offer details',
  priceReference: 'Price reference',
  comments: 'Offer comments',
  delivery: 'Delivery and offer details',
  deliveryInfo: 'Delivery information',
  notes: 'Internal Notes',
};

export const QUERY_KEYS = {
  addresses: 'addresses',
  apiStatus: 'apiStatus',
  booking: 'booking',
  bookingConfirmation: 'bookingConfirmation',
  bookingConfirmationSMS: 'bookingConfirmationSMS',
  bookingHistory: 'bookingHistory',
  bookingStock: 'bookingStock',
  bookings: 'bookings',
  bookingsConfig: 'bookingsConfig',
  config: 'config',
  contact: 'contact',
  contactInviteSearch: 'contactInviteSearch',
  contacts: 'contacts',
  enquiry: 'enquiry',
  enquiryList: 'enquiryList',
  listings: 'listings',
  listingAlert: 'listingAlert',
  listingDetails: 'listingDetails',
  marketplaceConfig: 'marketplaceConfig',
  marketplaceListing: 'marketplaceListing',
  marketplacePublicListing: 'marketplacePublicListing',
  marketplaceOffers: 'marketplaceOffers',
  newOrg: 'newOrg',
  offer: 'offer',
  offers: 'offers',
  org: 'organisation',
  orgs: 'organisations',
  picAttributeList: 'picAttributeList',
  picAutocomplete: 'picAutocomplete',
  picList: 'picList',
  priceGrid: 'priceGrid',
  priceGridEois: 'priceGridEois',
  priceGridListing: 'priceGridListing',
  priceGridOffers: 'priceGridOffers',
  priceGrids: 'priceGrids',
  privateGroup: 'privateGroup',
  privateGroups: 'privateGroups',
  productGrids: 'productGrids',
  products: 'products',
  subscriptions: 'subscriptions',
  summaryGrid: 'summaryGrid',
  summaryGrids: 'summaryGrids',
  user: 'user',
  users: 'users',
  visibilityContacts: 'visibilityContacts',
  visibilityPrivateGroups: 'visibilityPrivateGroups',
  orgMedia: 'orgMedia',
  orgMediaSearch: 'orgMediaSearch',
};

export const BUYER_ORG_STORAGE_KEY = 'activeBuyerOrg';

export const FIRST_LOGIN_STORAGE_KEY = 'firstLogin';
export const REDIRECT_URL = 'redirectURL';
export const NAVBAR = 'navbar';

export const APP = 'app';

export const COMMON_PLAN_FEATURES = [
  'Unlimited contacts',
  'Full price grid creation',
  'Broadcasts',
];

export const PUBLIC_CONTACT_PAGE = 'https://agoralivestock.com.au/contact';
export const PUBLIC_TERMS_PAGE = 'https://agoralivestock.com.au/terms-of-use';

export const UPGRADE_MESSAGES = {
  privateGroups:
    'Networks are groups of contacts you can send messages to, such as price changes or new grids. This lets you keep your suppliers are kept up to date with your prices. Networks could include preferred suppliers, certified suppliers or livestock agents.',
};

export const OFFER_REFETCH_INTERVAL = 10000;

export const STATUS_REFETCH_INTERVAL = 60000;

export const USER_MESSAGE = {
  notesVisiblity: '(Not visible to suppliers)',
};

export const cld = new Cloudinary({
  cloud: {
    cloudName: process.env.NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME,
  },
});

export const FREE_PLAN_FEATURES = {
  target: 'Ideal for Hobby Farmers',
  values: [
    { feature: 'Price alerts', info: 'Alerts when buyers update prices' },
    {
      feature: 'Create free listings on marketplace (success fees apply)',
      info: 'Creating a listing is free. There is a 5% success fee 5% success fee if your listing finds a counterparty via the platform',
    },
    {
      feature: 'Daily Market Update Newsletter',
      info: 'A daily market summary for sheep & goats and cattle markets',
    },
    {
      feature: 'Does not include access to detailed price grids',
      info: 'You will see summary prices but not details price information such as the buyers contact information',
    },
    { feature: '1 location' },
    { feature: '1 user' },
  ],
};
export const PLANS = [
  {
    plan: 'BASE',
    target: 'Ideal for Farmers',
    values: [
      { feature: 'Free features plus' },
      {
        feature: 'View full price grids',
        info: 'View buyer contact information and price grids',
      },
      {
        feature: 'Direct message buyers',
        info: 'Send a message to buyers via the app',
      },
      {
        feature: 'Reduced marketplace fees',
        info: 'If your listing successfully finds a counterparty via the platform, the success fee is reduced to 2% (from 5%)',
      },
      {
        feature: 'Online & in-app live chat support',
      },
    ],
  },
  {
    plan: 'STANDARD',
    target: 'Ideal for Agents, Traders and Studs',
    values: [
      { feature: 'Base features plus' },
      {
        feature: 'No marketplace fees',
        info: 'For annual subscriptions - pay no success fees at all. Capped at one listing per month for monthly subscriptions.',
      },
      {
        feature: 'Create your own marketplace',
        info: 'Integrate your marketplace listings on your own website so they automatically appear when you list them on Agora',
      },
      {
        feature: 'Publish saleyard reports',
        info: 'Share your written, video or audio market reports (and brand!) via the Agora app, website and email',
      },
      {
        feature: '1-on-1 onboarding session',
        info: "There's a lot of features to use here. We'll arrange a time to personally make sure you're getting the most out of all of them ",
      },
    ],
  },
  {
    plan: 'PREMIUM',
    target: 'Ideal for Larger Traders and Feedlots',
    values: [
      { feature: 'Standard plan features plus' },
      {
        feature: 'Premium stock wanted listings',
        info: "Put stock wanted listings on the front page of the Agora app and send alerts to let users know you're in the market",
      },
      {
        feature: 'Digital & Mobile Stockbook',
        info: 'Stop recording your purchases on paper, excel and whiteboards. Quickly capture them on your phone or computer and have access to them at all times. ',
      },
      {
        feature: 'Dedicated account manager',
        info: 'Your account manager will help ensure the platform is working for you as well as providing personal market updates and insights',
      },
      {
        feature: 'Premium market indicators & summaries',
        info: "Looking for summaries on market data (eg average NSW feeder cattle or trade lamb prices)? We'll build a report just for you",
      },
      {
        feature: 'View seller contact details',
        info: 'Skip the marketplace queue and contact sellers directly with access to their phone number and email address',
      },
      {
        feature: 'Early alerts on new marketplace listings',
        info: 'Get alerts from your account manager when new stock is listed on the marketplace',
      },
      {
        feature: '3 users (additional users $100/month)',
        info: 'Additional users available at $100/month',
      },
    ],
  },
  {
    plan: 'ENTERPRISE',
    target: 'Ideal for Processors & Corporate Feedlots',
    values: [
      { feature: 'Premium plan features plus' },
      {
        feature: 'Stock required reporting',
        info: 'Real-time reporting on how much stock you need and where the gaps are in your supply chain',
      },
      {
        feature: 'Price reporting',
        info: 'Get price and management reports on your purchases.',
      },
      {
        feature: 'Private prices - control who sees your prices',
        info: 'Control who can see your prices in the app',
      },
      {
        feature: 'Unlimited users',
      },
    ],
  },
];
