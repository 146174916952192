import { Ability, AbilityBuilder, AbilityClass } from '@casl/ability';
import {
  BuyerSummaryGrid,
  OrgDetail,
  User as UserBasic,
  UserDetailed,
} from './api/generated';
import { checkNumberLimitAllowed } from './helpers';

type Actions = 'create' | 'read' | 'update' | 'delete';
type User = UserBasic | UserDetailed;

type Subjects =
  | 'PrivateGroup'
  | 'Organisation'
  | 'User'
  | User
  | 'Subscription'
  | 'Prices'
  | 'Suppliers'
  | BuyerSummaryGrid
  | 'Stockbook'
  | 'BuyerSummaryGrid'
  | 'PicManager'
  | 'StockRequired'
  | 'ListingBroadcast'
  | 'MediaManager';

export type AppAbility = Ability<[Actions, Subjects]>;
export const AppAbility = Ability as AbilityClass<AppAbility>;

export function defineAbility(user: User, org: OrgDetail) {
  const { can, build } = new AbilityBuilder(AppAbility);

  // Limits based on subscription
  const limits = org.permissions?.limits;

  // Access based on subscription
  const access = org.permissions?.access;

  // No paid subscription
  if (!org.active_subscription) {
    if (org.is_admin) {
      can(['create', 'update'], 'Subscription');
      can('update', 'Organisation');
    }
    if (access?.stockbook) {
      can(['read'], 'Stockbook');
    }
    return build();
  }

  // Stockbook
  if (access?.stockbook) {
    can(['read'], 'Stockbook');
  }

  if (access?.stockbook) {
    // backend doesn't set a access property targeting Stock required specifically.
    // Therefore use Stockbook property
    can(['read'], 'StockRequired');
  }

  if (access?.pic_manager) {
    can(['read'], 'PicManager');
  }

  // Media
  if (access?.media_manager) {
    can('read', 'MediaManager');
  }

  // Contacts
  if (access?.prices) {
    can('read', 'Prices');
  }
  // Suppliers
  if (access?.contacts) {
    can('read', 'Suppliers');
  }

  // BuyerSummaryGrid
  if (access?.summary_grids) {
    can('read', 'BuyerSummaryGrid');
  }

  // Networks
  if (access?.private_groups) {
    can('read', 'PrivateGroup');
    if (checkNumberLimitAllowed(limits?.private_groups)) {
      can(['create', 'update', 'delete'], 'PrivateGroup');
    }
  }

  // User management
  can('delete', 'User', {
    user_id: { $ne: user.user_id },
  });

  // Summary grids
  can('delete', 'BuyerSummaryGrid', {
    'creator.user_id': { $eq: user.user_id },
  });
  can('update', 'BuyerSummaryGrid', {
    'creator.user_id': { $eq: user.user_id },
  });

  // Listing Broadcast
  if (access.listing_broadcast) {
    can('create', 'ListingBroadcast');
  }

  // Admin specific rules/overrides
  if (org.is_admin) {
    // Org
    can('update', 'Organisation');

    // Subscription
    can('update', 'Subscription');

    // Summary grids
    can('update', 'BuyerSummaryGrid');
    can('delete', 'BuyerSummaryGrid');
  }

  return build();
}

const defaultAbility = new Ability<[Actions, Subjects]>();

export default defaultAbility;
